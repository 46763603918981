<template>
  <div>
    <nav-menu />
      <section style="flex: 1 0 auto;">
        <div class="content-inside">
          <router-view />
        </div>
      </section>
  </div>
</template>

<script>
import NavMenu from '../components/layout/nav-menu.vue'
export default {
  components: {
    NavMenu
  }
}
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #137eb8;
$primary-invert: findColorInvert($primary);
$twitter: #4099ff;
$twitter-invert: findColorInvert($twitter);
$verde: #21c989;
$verde-invert: findColorInvert($verde);
$vermelho: #c9212f;
$vermelho-invert: findColorInvert($vermelho);
$laranja: #ff7f50;
$laranja-invert: findColorInvert($laranja);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  ),
  "twitter": (
    $twitter,
    $twitter-invert
  ),
  "verde": (
    $verde,
    $verde-invert
  ),
  "vermelho": (
    $vermelho,
    $vermelho-invert
  ),
  "laranja": (
    $laranja,
    $laranja-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "~bulma-extensions/src/sass/index.sass";

.margin-top {
  margin-top: 30px;
  margin-left: 5px;
  margin-right: 5px;
}
.margin-left {
  margin-left: 10px;
}
</style>
